import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { AppConfigService } from '@scriptac/common/core/services/app-config.service';

/** Coolie policy dialog component. */
@Component({
	selector: 'scriptaw-cookie-policy-dialog',
	templateUrl: './cookie-policy-dialog.component.html',
	styleUrls: ['./cookie-policy-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatDialogTitle,
		MatIconButton,
		MatDialogClose,
		MatIcon,
		CdkScrollable,
		MatDialogContent,
	],
})
export class CookiePolicyDialogComponent {

	/** Scripta URL. */
	public readonly scriptaUrl = inject(AppConfigService).scriptaUrl;
}
